import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import React from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

type Props = { consentBannerOpen: boolean; accept: boolean; toggleConsent: (accept: boolean) => void };

const CookieConsentBanner = ({ consentBannerOpen, accept, toggleConsent }: Props) => {
  return (
    <Drawer anchor="top" open={consentBannerOpen}>
      <Box sx={{ p: 2 }}>
        <h3 style={{ fontWeight: 'bold' }}>Notice</h3>
        <p>
          We and selected third parties use cookies or similar technologies for technical purposes and, with your
          consent, for measurement as specified in the cookie policy. Denying consent may make related features
          unavailable.
        </p>
        <p>
          You can freely give, deny, or withdraw your consent at any time. Use the “🍪 Accept” button to consent. Use
          the “Reject all” button to continue without accepting.
        </p>
        <Stack direction="column">
          <FormControlLabel control={<Switch defaultChecked={accept} name="Measurement" />} label="Measurement" />
          <FormControlLabel control={<Switch defaultChecked name="Necessary" disabled />} label="Necessary" />
        </Stack>
        <Box sx={{ width: '100%', p: 2, justifyContent: 'end', display: 'flex' }}>
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={() => toggleConsent(true)}>
              🍪 Accept all
            </Button>
            <Button variant="outlined" onClick={() => toggleConsent(false)}>
              Reject all
            </Button>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CookieConsentBanner;
