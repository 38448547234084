import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import fetchInsightsFilters from 'Services/InsightsServices/fetchInsightsFilters';
import { Filters } from 'Types/insightstypes';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const CountryFilterSelector = () => {
  const [countryOptions, setCountryOptions] = useState<string[]>([]);
  const { queryparams, setCountries, portalUser, countries } = useInsightsQuery();
  const { data, isLoading } = useQuery<Filters>({
    queryKey: ['insightsfilters', { ...queryparams }],
    queryFn: () => fetchInsightsFilters(queryparams),
    enabled: !!portalUser && countries.length === 0,
    refetchInterval: false,
  });

  useEffect(() => {
    if (data?.data?.data?.countries) {
      setCountries(data?.data?.data?.countries.filter((country) => country !== null) as string[]);
      setCountryOptions(data?.data?.data?.countries.filter((country) => country !== null) as string[]);
    }
  }, [data?.data?.data?.countries?.toString()]);

  return (
    <Grid item xs={12} sm={4}>
      <Autocomplete
        size="small"
        disablePortal
        disableListWrap
        disableCloseOnSelect
        clearOnEscape
        multiple
        loading={isLoading}
        filterSelectedOptions
        options={countryOptions}
        id="country-filter-selector"
        sx={{ width: '100%' }}
        value={countries}
        renderInput={(params) => (
          <TextField
            InputLabelProps={{ shrink: true }}
            {...params}
            label={isLoading ? <CircularProgress color="inherit" size={20} /> : 'Country filter'}
            placeholder="Country filter"
          />
        )}
        onChange={(e, value) => {
          console.log(value);
          setCountries(value as string[]);
        }}
        onInputChange={(e, value) => {
          setCountryOptions(
            data?.data?.data?.countries.filter((country) => country?.toLowerCase().includes(value?.toLowerCase()))
          );

          if (value === '') {
            setCountryOptions(data?.data?.data?.countries.filter((country) => country !== null) as string[]);
          }
        }}
      />
    </Grid>
  );
};

export default CountryFilterSelector;
