import MovingIcon from '@mui/icons-material/Moving';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import { insightsKPIWithCurrencyCode } from 'Utils/humanReadableTitles';
import HelpModal from '../HelpModal/index';

type Props = {
  label: string;
  helpText: string;
  helpTextTitle?: string;
  value: string;
  comparisonValue?: string;
  sortKey: string | number;
  metricValue: number;
  metricComparisonValue?: number;
};

function ChartHeader({
  label,
  helpText,
  helpTextTitle = null,
  value,
  comparisonValue = null,
  sortKey,
  metricValue,
  metricComparisonValue = null,
}: Props) {
  const { shallCompare, portalUser, dateRange, alternateDateRange } = useInsightsQuery();
  const currencycode = portalUser?.currency || 'N/A';
  const hasCurrencyCode = insightsKPIWithCurrencyCode.includes(sortKey as string);

  const direction = metricComparisonValue ? (
    metricValue > metricComparisonValue ? (
      <MovingIcon />
    ) : (
      <TrendingDownIcon />
    )
  ) : (
    '-'
  );
  const percentageChange = metricComparisonValue
    ? Math.round(((metricValue - metricComparisonValue) / metricComparisonValue) * 100)
    : '-';

  return (
    <Grid container>
      <Grid item xs={11}>
        <h4>{label.toUpperCase()}</h4>
      </Grid>
      <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
        <HelpModal helptext={helpText} helpTextTitle={helpTextTitle} />
      </Grid>
      <Grid item xs="auto">
        <Tooltip
          arrow
          placement="top"
          title={`${dayjs(dateRange[0]).format('YYYY-MM-DD')} - ${dayjs(dateRange[1]).format('YYYY-MM-DD')}`}
        >
          <Stack direction="row" spacing={2}>
            <Typography sx={{ fontSize: '1.77rem', fontWeight: 600 }}>{`${value} ${
              hasCurrencyCode ? currencycode : ''
            }`}</Typography>
            {shallCompare && (
              <Stack direction="column">
                <span>{direction}</span>
                <Typography sx={{ marginTop: '-7px' }} variant="caption">{`${percentageChange} %`}</Typography>
              </Stack>
            )}
            {shallCompare && <Divider orientation="vertical" flexItem />}
          </Stack>
        </Tooltip>
      </Grid>
      {shallCompare && (
        <Grid item xs sx={{ paddingLeft: '12px' }}>
          <Tooltip
            arrow
            placement="top"
            title={`${dayjs(alternateDateRange[0]).format('YYYY-MM-DD')} - ${dayjs(alternateDateRange[1]).format(
              'YYYY-MM-DD'
            )}`}
          >
            <Stack direction="column">
              <h6 style={{ fontSize: '0.8rem', opacity: 0.7, lineHeight: 1, marginBottom: 0 }}>Comparison period</h6>
              <span style={{ fontSize: '1.2rem', opacity: 0.7 }}>{`${comparisonValue} ${
                hasCurrencyCode ? currencycode : ''
              }`}</span>
            </Stack>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}

export default ChartHeader;
