import { useEffect } from 'react';
import { Alert } from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import { useQuery } from '@tanstack/react-query';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import fetchAggregatedBudgetData from 'Services/InsightsServices/fetchAggregatedBudgetData';
import fetchBudgetDistributionData from 'Services/InsightsServices/fetchBudgetDistributionData';
import { BudgetDataByDateType } from 'Types/insightstypes';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import BudgetDataMultiView from './BudgetDataMultiView';
import ErrorBoundary from './Components/ErrorBoundary';
import LoadingScreen from './Components/LoadingScreen';

type QueryReturnType = {
  data: { data: BudgetDataByDateType[] };
  isLoading: boolean;
};

function BudgetDataViews() {
  const {
    queryparams,
    isReadyToRun,
    shallCompare,
    alternateStartDate,
    alternateEndDate,
    setPlatformsEnabled,
    setPlatforms,
  } = useInsightsQuery();

  const { data: aggregatedbydate, isLoading: isLoadingAggregatedByDate } = useQuery<QueryReturnType>({
    queryKey: ['budgetdistributiondata', { ...queryparams }],
    queryFn: () => fetchBudgetDistributionData(queryparams ),
    enabled: isReadyToRun,
  });

  const { data: aggregatedbydateComparisonData, isLoading: isLoadingAggregatedByDateComparisonData } =
    useQuery<QueryReturnType>({
      queryKey: [
        'budgetdistributiondataComparisonData',
        { ...queryparams, startdate: alternateStartDate, enddate: alternateEndDate },
      ],
      queryFn: () =>
      fetchBudgetDistributionData({
          ...queryparams,
          startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
          enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
        }),
      enabled: isReadyToRun && shallCompare,
    });


    const { data: aggregatedtotal, isLoading: isLoadingAggregatedTotal } = useQuery({
      queryKey: ['aggregatedBudgetData', { ...queryparams }],
      queryFn: () => fetchAggregatedBudgetData(queryparams),
      enabled: isReadyToRun,
    });
    
  
    const { data: aggregatedTotalComparison, isLoading: isLoadingAggregatedTotalComparison } = useQuery({
      queryKey: ['aggregateddataBudgetComparison', {
        ...queryparams,
        startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
        enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
      }],
      queryFn: () =>
      fetchAggregatedBudgetData({
        ...queryparams,
        startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
        enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
      }),
      enabled: isReadyToRun && shallCompare,
    });

        
  const tooLittleData =
    (!aggregatedbydate?.data?.data || aggregatedbydate?.data?.data?.length === 0) && !isLoadingAggregatedByDate;

  const tooLittleComparisonData =
    shallCompare &&
    (!aggregatedTotalComparison?.data?.data ||
      !aggregatedbydateComparisonData?.data?.data ||
      aggregatedbydateComparisonData?.data?.data?.length === 0) &&
    !isLoadingAggregatedByDateComparisonData;

  useEffect(() => {
    setPlatformsEnabled(true);

    setPlatforms(['meta', 'google', 'bing']);

    return () => {
      setPlatformsEnabled(true);
    };
  }, []);

  return isLoadingAggregatedByDate || isLoadingAggregatedTotal || isLoadingAggregatedByDateComparisonData ? (
    <LoadingScreen />
  ) : (
    <ErrorBoundary>
      {tooLittleData || tooLittleComparisonData ? (
        <>
          {tooLittleData && (
            <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
              <AlertTitle>Info</AlertTitle>
              There's not enough data. Please select a different date range.
            </Alert>
          )}
          {tooLittleComparisonData && (
            <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
              <AlertTitle>Info</AlertTitle>
              There's not enough data to compare with the selected date range.
            </Alert>
          )}
        </>
      ) : (
        <BudgetDataMultiView
          dataByDate={aggregatedbydate?.data?.data}
          comparisondata={aggregatedbydateComparisonData?.data?.data || []}
          dataTotal={aggregatedtotal?.data?.data?.[0] || null}
          comparisonTotal={aggregatedTotalComparison?.data?.data?.[0] || null}
        />
      )}
    </ErrorBoundary>
  );
}

export default BudgetDataViews;
