const helptexts = {
  IMPRESSIONS_CHANGE_THRESHHOLD: {
    title: 'Impressions Change is used to quickly identify large drops in impressions',
    content:
      'Impressions  change is the difference between the latest number of impressions and the average number. Set the number to filter the table. Default is -40% which means that only campaigns where impressions have DECREASED with more than (or equal to) 40 % vill be displayed.',
  },
  IMPRESSIONS_LATEST_SWITCH: {
    title: 'Zero impressions',
    content: 'Flip the switch to only display stores with a latest record of zero impressions.',
  },
  FILTER_ON_ME: {
    title: 'Filter On Me',
    content: 'Filter the table on the user that is currently logged in. CSM only as of now',
  },
  WEIGHTED_KEYWORDS: {
    title: 'Weighted Keywords',
    content:
      'Weighted quality score is calculated by multiplying conversion value with a quality score scaled down by a factor of 100.',
  },
  POTENTIAL_KEYWORDS: {
    title: 'Potential Keywords',
    content:
      'Potential keywords are selected from the lowest quality score keywords in the campaign, sorted by highest click through rate',
  },
  IMPRESSIONS: {
    title: 'Impressions',
    content:
      'An impression is counted each time your ad is shown on a search result page or other sites. Each time your ad appears on, let’s say, Google or Meta, it’s counted as one impression. In some cases, only a section of your ad may be shown. For example, in Google Maps, your business name and location may only be shown, or your business name and the first line of your ad copy.',
  },
  CLICKS: {
    title: 'Clicks',
    content:
      'A click is counted each time a user clicks on your ad. A click is counted when a user clicks on your ad, even if they don’t end up visiting your website. For example, if a user clicks on your ad, then closes the tab, the click is still counted.',
  },
  COST: {
    title: 'Cost',
    content: 'The total amount of money you have spent on your ads.',
  },
  CONVERSIONS: {
    title: 'Conversions',
    content:
      'A conversion is counted each time a user takes a desired action on your website after clicking on your ad. For example, if a user clicks on your ad, then makes a purchase on your website, the click and the conversion are both counted.',
  },
  CONVERSIONVALUE: {
    title: 'Conversion Value',
    content:
      'The total value of all conversions. Conversion value is calculated by multiplying the conversion value with the number of conversions.',
  },
  CPC: {
    title: 'Cost Per Click',
    content:
      'The average amount you pay each time a user clicks on your ad. To calculate the average CPC, divide your total cost by the number of clicks.',
  },
  COSTPERCLICK: {
    title: 'Cost Per Click',
    content:
      'The average amount you pay each time a user clicks on your ad. To calculate the average CPC, divide your total cost by the number of clicks.',
  },
  CTR: {
    title: 'Click Through Rate',
    content:
      'The percentage of impressions that resulted in a click. To calculate the CTR, divide the number of clicks by the number of impressions.',
  },
  CLICKTHROUGHRATE: {
    title: 'Click Through Rate',
    content:
      'The percentage of impressions that resulted in a click. To calculate the CTR, divide the number of clicks by the number of impressions.',
  },
  IMPRESSIONSHARE: {
    title: 'Impression Share',
    content:
      'The percentage of impressions that your ads receive compared to the total number of impressions that your ads could get. To calculate the impression share, divide the number of impressions that your ads received by the estimated number of impressions that your ads were eligible to receive.',
  },
  SEARCHIMPRESSIONSHARE: {
    title: 'Impression Share',
    content:
      'The percentage of impressions that your ads receive compared to the total number of impressions that your ads could get. To calculate the impression share, divide the number of impressions that your ads received by the estimated number of impressions that your ads were eligible to receive.',
  },
  SEARCHVOLUME: {
    title: 'Search Volume',
    content: 'The average number of times people have searched for a keyword per month, during the last 12 months.',
  },
  QUALITYSCORE: {
    title: 'Quality Score',
    content:
      'Quality Score is an estimate of the quality of your ads, keywords, and landing pages. Higher quality ads can lead to lower prices and better ad positions.',
  },
  LANDINGPAGEEXPERIENCE: {
    title: 'Landing Page Experience',
    content:
      'Landing page experience is AdWords’ measure of how well your website gives people who click your ads exactly what they’re looking for--quickly and effortlessly. Landing page experience is represented on a 1-to-10 scale, with 1 being the lowest and 10 being the highest.',
  },
  ROAS: {
    title: 'Return On Ad Spend',
    content:
      'Return on ad spend (ROAS) is a marketing metric that measures the amount of revenue your business earns for each dollar spent on advertising. ROAS helps online businesses evaluate which methods are working and how they can improve future advertising efforts.',
  },
  CPA: {
    title: 'Cost Per Acquisition',
    content:
      'Cost per acquisition (CPA), also known as cost per action, pay per acquisition (PPA) and cost per conversion (CPC), is an online advertising pricing model where the advertiser pays for a specified acquisition - for example a sale, click, or form submit (e.g., contact request, newsletter sign up, registration etc.)',
  },
  CONVERSIONRATE: {
    title: 'Conversion Rate',
    content:
      'Conversion rate is the percentage of visitors to your website that complete a desired goal (a conversion) out of the total number of visitors.',
  },
  CONVRATE: {
    title: 'Conversion Rate',
    content:
      'Conversion rate is the percentage of visitors to your website that complete a desired goal (a conversion) out of the total number of visitors.',
  },
  RETURNONEXPENDITURVALUE: {
    title: 'Return On Expenditure Value',
    content:
      'Return on ad spend (ROAS) is a marketing metric that measures the amount of revenue your business earns for each dollar spent on advertising. ROAS helps online businesses evaluate which methods are working and how they can improve future advertising efforts.',
  },
  COS: {
    title: 'Cost Of Sales',
    content:
      'Cost of sales (COS), also known as the cost of revenue, is the total of all costs directly related to the production of goods or services sold by a company. This amount includes the cost of the materials used in creating the good along with the direct labor costs used to produce the good.',
  },
  SEARCHINTEREST: {
    title: 'Search Interest',
    content:
      'Search interest is an index calculated from a period of at least one year prior to the end date in the range. 100 indicates the day with the largest search volume. A value of 50 indicates a day has half the search interest of the peak, and a value of 0 indicates a day has less than 1/100th the search interest that the peak does.',
  },
  FREQUENCY: {
    title: 'Frequency',
    content:
      'Frequency is the average number of times that each person saw your ad. ' +
        'It is estimated by dividing the total number of impressions by the total number of unique users who saw the ad.' +
        ' For example, if an ad was shown 5 times and 4 people saw it, the frequency is 1.25. ',
  },
    LANDINGPAGEVIEWS: {
    title: 'Landing page views',
    content:
      'A landing page view is when a person clicks on your ad link ' +
        'and then successfully loads at least some of your ad destination URL or Instant Experience.'
  },

  PAGEVIEW: {
    title: 'Landing page views',
    content:
      'A landing page view is when a person clicks on your ad link ' +
        'and then successfully loads at least some of your ad destination URL or Instant Experience.'
  },

    NEWUSERS: {
    title: 'New users',
    content:
      'New users are users who have never been to your website before.'
    },

    RETURNINGUSERS: {
    title: 'Returning users',
    content:
      'Returning users have already visited your website before.'
    },

    UNIQUEUSERS: {
      title: 'Unique users',
      content:
        '\n' +
          'A unique user refers to a person who has visited your website at least ' +
          'once and is counted also only once in the reporting time period. '
      },
  NOOFOPERATIONS: {
      title: 'Number of optimizations',
      content:
        '\n' +
          'The total number of optimizations made by Amanda in the given time period to achieve the campaign results.'
      },
      BUDGETAMOUNT: {
        title: 'Budget amount',
        content:
          '\n' +
          "Budget amounts are estimates based on today's calculations that reflect how much campaign budget will be allocated for the upcoming period.",
        },
        REACH: {
          title: 'Reach',
          content:
            '\n' +
              'Reach is the number of unique users on Meta who have seen your posts, page, or advertisements.'
          },


};


export default helptexts;

export type HelpTextType = typeof helptexts;
