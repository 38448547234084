import fetchGoogleGampaignData from 'Services/InsightsServices/fetchGoogleGampaignData';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import { GoogleCampaignDataByDateType } from '../../Types/insightstypes';
import LoadingScreen from './Components/LoadingScreen';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useInsightsQuery } from '../../Context/InsightsQueryContext';
import { useQuery } from '@tanstack/react-query';
import ErrorBoundary from './Components/ErrorBoundary';
import GoogleCampaignDataMultiView from './GoogleCampaignDataMultiView';
import React, { useEffect } from 'react';
import fetchAggregatedData from '../../Services/InsightsServices/fetchAggregatedData';

type QueryReturnType = {
  data: { data: GoogleCampaignDataByDateType[] };
  isLoading: boolean;
};

function GoogleCampaignDataViews() {
  const {
    queryparams,
    isReadyToRun,
    shallCompare,
    alternateStartDate,
    alternateEndDate,
    setPlatformsEnabled,
    setPlatforms,
  } = useInsightsQuery();

  //Ordinary by day
  const { data: googlecampaigndata, isLoading: isLoadingGoogleCampaignData } = useQuery<QueryReturnType>({
    queryKey: ['googlecampaigndata', { ...queryparams }],
    queryFn: () => fetchGoogleGampaignData(queryparams),
    enabled: isReadyToRun,
  });

  //Ordinary Comparison by day
  const { data: googlecampaignComparisonData, isLoading: isLoadingGoogleCampaignComparisonData } =
    useQuery<QueryReturnType>({
      queryKey: [
        'googlecampaigndataComparisonData',
        { ...queryparams, startdate: alternateStartDate, enddate: alternateEndDate },
      ],
      queryFn: () =>
        fetchGoogleGampaignData({
          ...queryparams,
          startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
          enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
        }),
      enabled: isReadyToRun && shallCompare,
    });

  useEffect(() => {
    setPlatformsEnabled(false);

    setPlatforms(['google']);

    return () => {
      setPlatformsEnabled(true);
    };
  }, []);

  //Aggregated for a specific period
  const { data: googlecampaigndataTotal, isLoading: isLoadingGoogleCampaignDataTotal } = useQuery({
    queryKey: ['aggregateddataForGoogle', { ...queryparams }],
    queryFn: () => fetchAggregatedData({ ...queryparams, channels: ['google'] }),
    enabled: isReadyToRun,
  });

  //Aggregated comparison for a specific period
  const { data: googleTotalComparison, isLoading: isLoadingGoogleTotalComparison } = useQuery({
    queryKey: ['aggregateddataComparisonForGoogle', { 
      ...queryparams, 
    channels: ['google'], 
    startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
    enddate: dayjs(alternateEndDate).format('YYYY-MM-DD') }],
    queryFn: () =>
      fetchAggregatedData({
        ...queryparams,
        channels: ['google'],
        startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
        enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
      }),
    enabled: isReadyToRun && shallCompare,
  });

  const tooLittleData =
    (!googlecampaigndata?.data?.data || googlecampaigndata?.data?.data?.length === 0) && !isLoadingGoogleCampaignData;

  const tooLittleComparisonData =
    shallCompare &&
    (!googleTotalComparison?.data?.data ||
      !googlecampaignComparisonData?.data?.data ||
      googlecampaignComparisonData?.data?.data?.length === 0) &&
    !isLoadingGoogleCampaignComparisonData;

  return isLoadingGoogleCampaignData || isLoadingGoogleCampaignDataTotal || isLoadingGoogleCampaignComparisonData ? (
    <LoadingScreen />
  ) : (
    <ErrorBoundary>
      {tooLittleData || tooLittleComparisonData ? (
        <>
          {tooLittleData && (
            <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
              <AlertTitle>Info</AlertTitle>
              There's not enough data. Please select a different date range.
            </Alert>
          )}
          {tooLittleComparisonData && (
            <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
              <AlertTitle>Info</AlertTitle>
              There's not enough data to compare with the selected date range.
            </Alert>
          )}
        </>
      ) : (
        <GoogleCampaignDataMultiView
          dataByDay={googlecampaigndata?.data?.data}
          comparisondata={googlecampaignComparisonData?.data?.data || []}
          dataTotal={googlecampaigndataTotal?.data?.data?.[0] || null}
          comparisonTotal={googleTotalComparison?.data?.data?.[0] || null }
        />
      )}
    </ErrorBoundary>
  );
}

export default GoogleCampaignDataViews;
