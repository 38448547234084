import Grid from '@mui/material/Grid';
import helptexts, { HelpTextType } from 'Assets/JSON/helptexts';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import useChartDimensions from 'Hooks/useChartDimensions';
import useComparisonData from 'Hooks/useComparisonData';
import { AggregatedDataByDateType, AggregatedDataType } from 'Types/insightstypes';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import { HumanReadableInsightsKPIsType, humanReadable } from 'Utils/humanReadableTitles';
import * as d3 from 'd3';
import { Suspense } from 'react';
import { BEIGE, BLUE, ORANGE, TEAL } from '../../Utils/colors';
import ChartHeader from './Components/ChartHeader';
import ChartSwitchWrapper from './Components/ChartSwitchWrapper';
import ErrorBoundary from './Components/ErrorBoundary';
import NivoBarDelta from './Components/NivoBarDelta';
import NivoLineChart from './Components/NivoLineChart';
import NivoMultipleLineChart from './Components/NivoMultipleLineChart/NivoMultipleLineChart';

type Props = {
  dataByDate: AggregatedDataByDateType[];
  comparisondata: AggregatedDataByDateType[];
  dataTotal: AggregatedDataType;
  comparisonTotal: AggregatedDataType;
};
interface Datum {
  x?: Date | number | string;
  y?: Date | number | string;
  [key: string]: any;
}

interface Serie {
  id: string | number;
  sortKey?: string | number;
  data: Datum[];
  [key: string]: any;
}

function dataGetter(
  accumulator: { [key: string]: Serie },
  currentValue: AggregatedDataByDateType,
  currentIndex: number,
  array: AggregatedDataByDateType[],
  isOriginal: boolean
) {
  const keys = Object.keys(currentValue).sort((a, b) => {
    const indexA = metricSortOrder.indexOf(a.toLowerCase());
    const indexB = metricSortOrder.indexOf(b.toLowerCase());

    // If a and b are both in metricSortOrder, compare their positions
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only a is in metricSortOrder, it should come before b
    if (indexA !== -1) {
      return -1;
    }

    // If only b is in metricSortOrder, it should come before a
    if (indexB !== -1) {
      return 1;
    }

    // If neither a nor b are in metricSortOrder, no change in order
    return 0;
  });

  keys.forEach((key) => {
    const accumulatorKey = key as keyof AggregatedDataByDateType;
    /**
     * searchimpressionshare must NOT be displayed among aggregated data
     * because total volume only exists for Google so the metric will be
     * misleading for other channels
     */
    if (accumulatorKey === 'searchimpressionshare' || accumulatorKey === 'impressionshare') return accumulator;
    if (key !== 'reportdate') {
      const id = isOriginal ? key : `${key}_comparison`;
      if (!accumulator[accumulatorKey]) {
        accumulator[accumulatorKey] = { id, label: key, sortKey: key, data: [] };
      }
      accumulator[accumulatorKey].id = id;
      accumulator[accumulatorKey].label = key;
      accumulator[accumulatorKey].sortKey = key;
      accumulator[accumulatorKey].data = [
        ...accumulator[accumulatorKey].data,
        { x: dayjs(currentValue.reportdate).format('YYYY-MM-DD'), y: currentValue[accumulatorKey] },
      ];
    }
  });

  return accumulator;
}

type DataPerCategoryType = {
  [key: string]: Serie;
};

const chartSettings = {
  marginTop: 20,
  marginRight: 15,
  marginBottom: 40,
  marginLeft: 10,
};

const metricSortOrder = [
  'cost',
  'conversionvalue',
  'roas',
  'returnonexpenditurevalue',
  'conversions',
  'conversionrate',
  'convrate',
  'cpc',
  'impressions',
  'searchvolume',
  'searchimpressionshare',
  'impressionshare',
  'clicks',
  'ctr',
  'clickthroughrate',
  'costperclick',
  'cpa',
  'frequency',
  'landingpageviews',
  'newusers',
  'returningusers',
  'uniqueusers',
  'noofoperations',
];

function AggregatedDataByDateMultiView({ dataByDate, dataTotal, comparisondata, comparisonTotal }: Props) {
  const { portalUser, shallCompare, queryparams, platforms } = useInsightsQuery();
  const [ref, dms] = useChartDimensions(chartSettings);
  const [originalData, comparisonData, ...errors]: [DataPerCategoryType, DataPerCategoryType, ...string[]] =
    useComparisonData([dataByDate, comparisondata, dataGetter, queryparams]);

  return (
    <Grid container spacing={2}>
      {originalData &&
        Object.keys(originalData).map((key) => {
          const data = originalData[key as keyof AggregatedDataType];
          const comparisondata = comparisonData[key as keyof AggregatedDataType];

          const formatString =
            humanReadable.insightsKPIValueFormat[data?.sortKey as keyof HumanReadableInsightsKPIsType](portalUser);

          const metricValue = dataTotal[data?.sortKey as keyof AggregatedDataType];
          const metricComparisonValue = shallCompare
            ? comparisonTotal[data?.sortKey as keyof AggregatedDataType]
            : null;

          const formattedMetricValue = d3.format(formatString)(metricValue);
          const formattedMetricComparisonValue = shallCompare ? d3.format(formatString)(metricComparisonValue) : null;

          const helpTextKey =
            data?.label?.toUpperCase() === 'RETURNONEXPENDITUREVALUE'
              ? portalUser.return_on_expenditure_type.toUpperCase()
              : (data?.label?.toUpperCase() as keyof HelpTextType);

          const helpTextTitle = helptexts[helpTextKey as keyof HelpTextType]?.title;
          const helpText = helptexts[helpTextKey as keyof HelpTextType]?.content;
          const label =
            typeof data?.label === 'string'
              ? humanReadable.insightsKPIs[data?.label as keyof HumanReadableInsightsKPIsType](portalUser).toUpperCase()
              : data?.label;

          /// Filter out 'searchvolume' if 'meta' is the only channel selected
          if (
            data?.label === 'searchvolume' &&
            queryparams.channels.includes('meta') &&
            queryparams.channels.length === 1
          ) {
            return null;
          }

          return (
            <Suspense fallback={<div>Loading...</div>} key={data?.id}>
              {data?.label === 'searchvolume' ? (
                <Grid item xs={12} sx={{ maxHeight: '380px' }}>
                  <div style={{ backgroundColor: BEIGE, padding: '12px' }}>
                    <ErrorBoundary>
                      <ChartHeader
                        label="Search Interest"
                        key={data?.sortKey}
                        sortKey={data?.sortKey}
                        helpText={helptexts.SEARCHINTEREST.content}
                        helpTextTitle={helptexts.SEARCHINTEREST.title}
                        value={formattedMetricValue}
                        comparisonValue={formattedMetricComparisonValue}
                        metricValue={metricValue}
                        metricComparisonValue={metricComparisonValue}
                      />
                    </ErrorBoundary>
                    <ErrorBoundary>
                      {shallCompare && comparisondata ? (
                        <ChartSwitchWrapper>
                          {(showDelta) =>
                            !showDelta ? (
                              <NivoMultipleLineChart
                                data={[data, comparisondata]}
                                foregroundColor={[ORANGE, BLUE]}
                                backgroundColor={TEAL}
                              />
                            ) : (
                              <NivoBarDelta
                                data={[data, comparisondata]}
                                foregroundColor={[ORANGE, BLUE]}
                                backgroundColor={TEAL}
                              />
                            )
                          }
                        </ChartSwitchWrapper>
                      ) : (
                        <div className="Chart__wrapper" ref={ref} style={{ height: '240px', marginTop: '22px' }}>
                          <Suspense fallback={<div>Loading...</div>}>
                            <NivoLineChart
                              key={data?.label}
                              data={data}
                              hasTrendLine
                              foregroundColor={[ORANGE, BLUE]}
                              backgroundColor={TEAL}
                            />
                          </Suspense>
                        </div>
                      )}
                    </ErrorBoundary>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} md={6} lg={4} sx={{ maxHeight: '380px' }}>
                  <div style={{ backgroundColor: BEIGE, padding: '12px' }}>
                    <ErrorBoundary>
                      <ChartHeader
                        label={label}
                        key={data?.sortKey}
                        sortKey={data?.sortKey}
                        helpText={helpText}
                        helpTextTitle={helpTextTitle}
                        value={formattedMetricValue}
                        comparisonValue={formattedMetricComparisonValue}
                        metricValue={metricValue}
                        metricComparisonValue={metricComparisonValue}
                      />
                    </ErrorBoundary>
                    <ErrorBoundary>
                      {shallCompare && comparisondata ? (
                        <ChartSwitchWrapper>
                          {(showDelta) =>
                            !showDelta ? (
                              <NivoMultipleLineChart data={[data, comparisondata]} />
                            ) : (
                              <NivoBarDelta
                                data={[data, comparisondata]}
                                foregroundColor={[ORANGE, BLUE]}
                                backgroundColor={TEAL}
                              />
                            )
                          }
                        </ChartSwitchWrapper>
                      ) : (
                        <div className="Chart__wrapper" ref={ref} style={{ height: '240px', marginTop: '22px' }}>
                          <Suspense fallback={<div>Loading...</div>}>
                            <NivoLineChart key={data?.label} data={data} hasTrendLine />
                          </Suspense>
                        </div>
                      )}
                    </ErrorBoundary>
                  </div>
                </Grid>
              )}
            </Suspense>
          );
        })}
    </Grid>
  );
}

export default AggregatedDataByDateMultiView;
