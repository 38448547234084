import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Formik } from 'formik';
import React, { useState } from 'react';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useQuery } from '@tanstack/react-query';
import AmandaAiLogo from 'Pages/Insights/Components/TextLogo';
import { BLACK } from 'Utils/colors';
import { getCookie } from 'Utils/Helpers';
import { Navigate } from 'react-router';
import { useAuth } from '../../../Context/AuthContext';
import './flip.css';
import './login.css';

function Login() {
  const { user, setUser, setLoadingUser } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const matches = useMediaQuery('(max-width:600px)');
  const { data: closedformaintenance, isLoading } = useQuery({
    queryKey: ['toggles'],
    queryFn: () => axios.get('/api/maintenance'),
  });

  if (user) return <Navigate to="/" />;

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = (res) => {
    const { user: loggedinuser } = res.data;
    setUser(loggedinuser);
  };

  if (closedformaintenance && closedformaintenance?.data?.toggle)
    return (
      <Container sx={{ background: '#ffe128 !important', maxWidth: '100% !important' }}>
        <Box sx={{ padding: '12px', position: 'absolute', top: '12px', left: '12px' }}>
          <AmandaAiLogo width={144} color={BLACK} />
        </Box>
        <Box
          sx={{
            typography: 'body2',
            bgcolor: 'transparent',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              bgcolor: 'transparent',
              width: '70%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h1
              style={{
                marginTop: matches ? 0 : '-24px',
                fontSize: matches ? '3.125rem' : '6.25rem',
                letterSpacing: '-3px',
                fontWeight: 700,
                lineHeight: '80%',
                color: BLACK,
                textAlign: matches ? 'center' : 'left',
              }}
            >
              We&apos;re closed for maintenance. See you soon!
            </h1>
          </Box>
        </Box>
      </Container>
    );

  return (
    <Container sx={{ background: '#ffe128 !important', maxWidth: '100% !important' }}>
      <Box
        sx={{
          typography: 'body2',
          bgcolor: 'transparent',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!isLoading && closedformaintenance && !closedformaintenance?.data?.toggle ? (
          <Box
            sx={{
              position: 'relative',
              minHeight: '250px',
              width: matches ? '95%' : '500px',
              transform: 'rotateX(0deg)',
              padding: matches ? '12px' : 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Card sx={{ width: '100%', borderRadius: 0, position: 'relative' }} elevation={0}>
              <Formik
                initialValues={{
                  name: '',
                  pass: '',
                  csrfmiddlewaretoken: getCookie('csrftoken'),
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.className = 'Username is required';
                  }
                  if (!values.pass) {
                    errors.pass = 'Password is required';
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  const loginformdata = new FormData();
                  loginformdata.append('username', values.name);
                  loginformdata.append('password', values.pass);
                  setLoadingUser(true);

                  axios({
                    method: 'post',
                    url: '/accounts/login/',
                    data: loginformdata,
                    headers: {
                      'X-CSRFToken': values.csrfmiddlewaretoken,
                    },
                  })
                    .then((res) => {
                      handleLogin(res);
                    })
                    .catch((e) => {
                      setHasFailed(true);
                      if (e.response?.status === 403) {
                        setErrorMessage('Invalid username or password');
                      }
                      if (e.response?.status === 503) {
                        setErrorMessage('Service is temporarily unavailable. Please try again later.');
                      }
                    })
                    .then(() => setSubmitting(false))
                    .finally(() => setLoadingUser(false));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  submitForm,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <CardContent>
                      <Box
                        sx={{
                          marginBottom: '1.2rem',
                        }}
                      >
                        <img
                          src="https://amandaaiprodstorage2.blob.core.windows.net/portal/img/AmandaAI_Wordmark_White.png"
                          srcSet="https://amandaaiprodstorage2.blob.core.windows.net/portal/img/AmandaAI_Wordmark_White.png"
                          alt="Amanda AI logotype"
                          loading="lazy"
                          width={120}
                          style={{ filter: 'invert(1)' }}
                        />
                      </Box>
                      {hasFailed && <Alert severity="error">{errorMessage || 'Unknown Error'}</Alert>}
                      <Stack direction="column" spacing={2}>
                        <input
                          id="csrfmiddlewaretoken"
                          name="csrfmiddlewaretoken"
                          type="hidden"
                          onChange={handleChange}
                          value={values.csrfmiddlewaretoken}
                        />
                        <FormControl variant="standard" required>
                          <TextField
                            autoFocus
                            autoComplete={values.name}
                            id="name"
                            name="name"
                            label="Username"
                            variant="standard"
                            onChange={handleChange}
                            value={values.name}
                            required
                          />
                          {errors.name && touched.name && errors.name}
                        </FormControl>
                        <FormControl variant="standard" required>
                          <InputLabel htmlFor="pass">Password</InputLabel>
                          <Input
                            autoComplete={values.pass}
                            id="pass"
                            name="pass"
                            type={showPassword ? 'text' : 'password'}
                            label="pass"
                            variant="standard"
                            onChange={handleChange}
                            value={values.pass}
                            required
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  sx={{ background: 'transparent' }}
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                  disableRipple
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {errors.pass && touched.pass && errors.pass}
                        </FormControl>
                      </Stack>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        endIcon={<LockOpenIcon />}
                        color="secondary"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={() => submitForm()}
                        sx={{
                          borderRadius: '21px',
                          padding: '12px 15px 12px 15px',
                          color: 'white',
                          width: matches ? '100%' : '160px',
                          fontWeight: 600,
                          lineHeight: '1rem',
                          boxShadow: 'none !important',
                          marginRight: '12px',
                        }}
                      >
                        Sign in
                      </LoadingButton>
                    </CardActions>
                  </form>
                )}
              </Formik>
            </Card>
          </Box>
        ) : null}
        <Box sx={{ width: '100%', visibility: 'hidden' }}>Footer</Box>
      </Box>
    </Container>
  );
}

export default Login;
