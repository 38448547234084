import Dispatcher from 'Components/Dispatcher/Dispatcher';
import Loading from 'Components/Loading';
import { ClientProvider } from 'Context/ClientContext';
import Admit from 'Pages/Login/Login/Admit';
import Login from 'Pages/Login/Login/Login';
import React, { ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from 'Context/AuthContext';

interface ProtectedRouteProps {
  children: ReactNode | ReactNode[]; // Expect a single React element or an array of React elements
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user, loadingUser } = useAuth();
  if (loadingUser) return <Loading />;
  return user ? <>{children}</> : <Navigate to="/login" />;
};

function AppRoutes() {
  return (
    <Routes>
      <Route path="/admit/*" element={<Admit />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <ClientProvider>
              <Dispatcher />
            </ClientProvider>
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<h1>404 Not found</h1>} />
    </Routes>
  );
}

export default AppRoutes;
