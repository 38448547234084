import { AggregatedDataType, AggregatedDataSerie, AggregatedDataDatum, CampaignTypeData } from 'Types/insightstypes';
import React, { useMemo } from 'react';
import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import { TEAL, PINK, BEIGE, ORANGE, BLUE } from 'Utils/colors';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import Stack from '@mui/material/Stack';
import EastIcon from '@mui/icons-material/East';
import { humanReadable } from 'Utils/humanReadableTitles';
import { BasicTooltip } from '@nivo/tooltip';
import { enumerateDaysBetweenDates } from 'Utils/Helpers';
import dayjs from 'dayjs';

type Props = { data: [AggregatedDataSerie, AggregatedDataSerie]; foregroundColor?: string[]; backgroundColor?: string };

const CustomTooltip = ({ point }: PointTooltipProps) => (
  <BasicTooltip
    id={
      <span>
        <strong>{point.data.xFormatted}</strong> : {point.data.yFormatted}
      </span>
    }
    enableChip={true}
    color={point.serieColor}
  />
);

function NivoAreaChart({ data, foregroundColor = [ORANGE, BLUE], backgroundColor = BEIGE }: Props) {
  const { portalUser } = useInsightsQuery();
  let originaldata = structuredClone(data);
  const hasValues = originaldata[0]?.data?.length > 0;

  if (originaldata[0]?.data?.length > 0 && originaldata[1]) {
    originaldata[1].data = originaldata[0].data.map((dataPoint, index) => {
      const date = dataPoint.x; 
      const comparisonDataPoint = originaldata[1].data[index];
      let y = comparisonDataPoint?.y;

      if (typeof y === 'undefined' || isNaN(Number(y))) {
        y = 0; 
      }

      return { x: date, y };
  });
  }

  return hasValues ? (
    <ResponsiveLine
      animate={false}
      areaOpacity={0.3}
      curve="linear"
      enableArea
      data={originaldata}
      margin={{ top: 20, right: 40, bottom: 110, left: 50 }}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        useUTC: true,
        precision: 'day',
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      lineWidth={2}
      yFormat={
        originaldata[0]?.label
          ? humanReadable.insightsKPIValueFormat[originaldata[0]?.label as keyof AggregatedDataType](portalUser)
          : ' >-.2r'
      }
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 60,
        legendOffset: 52,
        legendPosition: 'middle',
        tickValues: 'every 7 days',
        format: '%b %d',
      }}
      axisLeft={{
        format: originaldata[0]?.label
          ? humanReadable.insightsKPIValueFormat[originaldata[0]?.label as keyof AggregatedDataType](portalUser)
          : ' >-.2r',
        tickValues: 3,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      enablePoints={true}
      enableGridX={true}
      enableGridY={true}
      pointSize={4}
      pointBorderWidth={2}
      pointLabelYOffset={-12}
      colors={foregroundColor}
      useMesh={true}
      crosshairType="bottom-left"
      legends={[
        {
          anchor: 'bottom',
          data: originaldata.map((datum, index) => {
            return {
              color: foregroundColor[index],
              id: datum?.id as string,
              label:
                typeof datum?.id === 'string'
                  ? datum?.id?.includes('comparison')
                    ? 'Comparison Period'
                    : 'Current Period'
                  : '-',
            };
          }),
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 70,
          itemsSpacing: 20,
          itemDirection: 'top-to-bottom',
          itemWidth: 110,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'square',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      tooltip={(node) => <CustomTooltip point={node.point} />}
    />
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        height: '80%',
        width: '100%',
      }}
    >
      <Stack direction="row" spacing={1}>
        <EastIcon fontSize="small" />
        <div className="text-gray-400 text-2xl">Not enough data available</div>
      </Stack>
    </div>
  );
}

export default NivoAreaChart;