import Grid from '@mui/material/Grid';
import helptexts, { HelpTextType } from 'Assets/JSON/helptexts';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import useChartDimensions from 'Hooks/useChartDimensions';
import useComparisonData from 'Hooks/useComparisonData';
import { BudgetDataByDateType, BudgetDataType } from 'Types/insightstypes';
import { dayjs } from 'Utils/DayJs/dayjsWrapper';
import { HumanReadableInsightsKPIsType, humanReadable } from 'Utils/humanReadableTitles';
import * as d3 from 'd3';
import { Suspense } from 'react';
import { BEIGE, BLUE, ORANGE, TEAL, LIGHTBEIGE } from '../../Utils/colors';
import ChartHeader from './Components/ChartHeader';
import ChartSwitchWrapper from './Components/ChartSwitchWrapper';
import ErrorBoundary from './Components/ErrorBoundary';
import NivoBarDelta from './Components/NivoBarDelta';
import NivoLineChart from './Components/NivoLineChart';
import NivoMultipleLineChart from './Components/NivoMultipleLineChart/NivoMultipleLineChart';
import { useState } from 'react';
import { Alert, Typography } from '@mui/material';


type Props = {
  dataByDate: BudgetDataByDateType[];
  comparisondata: BudgetDataByDateType[];
  dataTotal: BudgetDataType;
  comparisonTotal: BudgetDataType;
};
interface Datum {
  x?: Date | number | string;
  y?: Date | number | string;
  [key: string]: any;
}

interface Serie {
  id: string | number;
  sortKey?: string | number;
  data: Datum[];
  [key: string]: any;
}


function dataGetter(
  accumulator: { [key: string]: Serie },
  currentValue: BudgetDataByDateType,
  currentIndex: number,
  array: BudgetDataByDateType[],
  isOriginal: boolean
) {
  Object.keys(currentValue).forEach((key) => {
    if (key !== 'budgetdate') {
      const accumulatorKey = key as keyof BudgetDataByDateType;
      const id = isOriginal ? key : `${key}_comparison`;
      if (!accumulator[accumulatorKey]) {
        accumulator[accumulatorKey] = { id, label: key, sortKey: key, data: [] };
      }
      accumulator[accumulatorKey].data.push({
        x: dayjs(currentValue.budgetdate).format('YYYY-MM-DD'),
        y: currentValue[accumulatorKey],
      });
    }
  });

  return accumulator;
}


type DataPerCategoryType = {
  [key: string]: Serie;
};

const chartSettings = {
  marginTop: 20,
  marginRight: 15,
  marginBottom: 40,
  marginLeft: 10,
};


function BudgetDataMultiView({ dataByDate, dataTotal, comparisondata, comparisonTotal }: Props) {
  const { portalUser, shallCompare, queryparams} = useInsightsQuery();
  const [ref] = useChartDimensions(chartSettings);
  const [originalData, comparisonData]: [DataPerCategoryType, DataPerCategoryType, ...string[]] =
    useComparisonData([dataByDate, comparisondata, dataGetter, queryparams]);
    const [showAlert, setShowAlert] = useState(true);


return (
    <Grid container spacing={2}>
      {originalData &&
        Object.keys(originalData).map((key) => {
          const data = originalData[key as keyof BudgetDataType];
          const comparisondata = comparisonData[key as keyof BudgetDataType];

          const formatString =
            humanReadable.insightsKPIValueFormat[data?.sortKey as keyof HumanReadableInsightsKPIsType](portalUser);

            const metricValue = dataTotal?.[data?.sortKey as keyof BudgetDataType] ?? 0;
            const metricComparisonValue = shallCompare
            ? comparisonTotal[data?.sortKey as keyof BudgetDataType]
            : null;

          const formattedMetricValue = d3.format(formatString)(metricValue);
          const formattedMetricComparisonValue = shallCompare ? d3.format(formatString)(metricComparisonValue) : null;

          const helpTextKey = data?.label?.toUpperCase() as keyof HelpTextType;

          const helpTextTitle = helptexts[helpTextKey as keyof HelpTextType]?.title;
          const helpText = helptexts[helpTextKey as keyof HelpTextType]?.content;
          const label =
            typeof data?.label === 'string'
              ? humanReadable.insightsKPIs[data?.label as keyof HumanReadableInsightsKPIsType](portalUser).toUpperCase()
              : data?.label;

          return (
            <Suspense fallback={<div>Loading...</div>} key={data?.id}>
            <Grid item xs={12} sx={{ maxHeight: '380px' }}>
                <div style={{backgroundColor: BEIGE, padding: '12px'}}>
                    <ErrorBoundary>
                        <ChartHeader
                            label={label}
                            key={data?.sortKey}
                            sortKey={data?.sortKey}
                            helpText={helpText}
                            helpTextTitle={helpTextTitle}
                            value={formattedMetricValue}
                            comparisonValue={formattedMetricComparisonValue}
                            metricValue={metricValue}
                            metricComparisonValue={metricComparisonValue}
                        />
                    </ErrorBoundary>
                    <Typography 
                      variant="body2" 
                      style={{
                          backgroundColor: BEIGE, 
                          padding: '10px', 
                          marginBottom: '20px', 
                          borderRadius: '4px', 
                          width: 'auto', 
                          maxWidth: '100%',
                      }}>
                       Budget amounts are campaign expenditure estimates made by Amanda.
                  </Typography>
                    <ErrorBoundary>
                        {shallCompare && comparisondata ? (
                            <ChartSwitchWrapper>
                                {(showDelta) =>
                                    !showDelta ? (
                                        <NivoMultipleLineChart data={[data, comparisondata]}/>
                                    ) : (
                                        <NivoBarDelta
                                            data={[data, comparisondata]}
                                            foregroundColor={[ORANGE, BLUE]}
                                            backgroundColor={TEAL}
                                        />
                                    )
                                }
                            </ChartSwitchWrapper>
                        ) : (
                            <div className="Chart__wrapper" ref={ref}
                                 style={{height: '240px', marginTop: '22px'}}>
                                <Suspense fallback={<div>Loading...</div>}>
                                <NivoLineChart key={data.label} data={data} customYScale={{min: 0,}}/>
                              </Suspense>
                            </div>
                        )}
                    </ErrorBoundary>
                </div>
            </Grid>
        </Suspense>
    );
})}

        {showAlert && (
          <Grid item xs={12} sx={{ maxHeight: '380px' }}>
              <Alert severity="info" sx={{
                  backgroundColor: LIGHTBEIGE, 
                  width: '100%'
                }}>
                Choosing different time periods for comparison won't have an effect since budget estimates 
                reflect only today's calculations for the current month.
              </Alert>
            
          </Grid>
        )}
</Grid>
);
}


export default BudgetDataMultiView;

