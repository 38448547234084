import { ThemeProvider, createTheme } from '@mui/material/styles';

import Fab from '@mui/material/Fab';

import { BLUE, LIGHTBLUE, ORANGE, PINK, YELLOW } from 'Utils/colors';
import 'antd/dist/antd.less';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import { Zoom } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CookieConsentBanner from './Components/CookieConsentBanner';
import { useAuth } from './Context/AuthContext';
import AppRoutes from './Routes/AppRoutes';
import './Styles/body.css';

const theme = createTheme({
  palette: {
    primary: {
      main: BLUE,
    },
    secondary: {
      main: ORANGE,
    },
    warning: {
      main: YELLOW,
    },
    info: {
      main: LIGHTBLUE,
    },
    pink: {
      main: PINK,
    },
  },
  typography: {
    fontFamily: [
      '"Suisse Intl"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          WebkitAutofilll: 'red',
          '--TextField-brandBorderColor': '#E0E3E7',
          '--TextField-brandBorderHoverColor': 'rgba(0, 111, 255, 1)',
          '--TextField-brandBorderFocusedColor': 'rgba(0, 111, 255, 1)',
          '& label.Mui-focused': {
            color: 'rgba(0, 111, 255, 1)',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          WebkitAutofill: 'red',
          fontSize: '1.111rem',
          lineHeight: '2rem',
          padding: '12px 4px',
          backgroundColor: 'white',
          '--TextField-brandBorderColor': '#E0E3E7',
          '&:before': {
            borderBottom: '2px solid var(--TextField-brandBorderColor)',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '2px solid rgba(0, 111, 255, 1)',
          },
          '&.Mui-focused:after': {
            borderBottom: '2px solid rgba(0, 111, 255, 1)',
          },
        },
      },
    },
  },
});

function LoginApp() {
  const { user } = useAuth();
  const [accept, setAccept] = useState(false);
  const [consentBannerOpen, setConsentBannerOpen] = useState(false);
  const matches = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    if (document.cookie) {
      const cookienames = [];
      const cookies = document.cookie.split(';');
      cookies.forEach((cookie) => {
        const cookieParts = cookie.split('=');
        const cookieName = cookieParts[0].trim();
        cookienames.push(cookieName);
        if (cookieName === 'cookieConsent') {
          const cookieValue = cookieParts[1];
          if (cookieValue === 'true') {
            // Initialize Tag Manager
            const tagManagerArgs = {
              gtmId: 'GTM-KF2QRNQ',
            };
            TagManager.initialize(tagManagerArgs);
            setAccept(true);
          }
          if (cookieValue === 'false') {
            setAccept(false);
          }
        }
      });
      // If no cookieConsent cookie, show banner

      if (!cookienames.some((name) => name === 'cookieConsent')) {
        setConsentBannerOpen(true);
      }
    }
  }, []);

  // Expire a Cookie
  function expireCookie(cName, cValue) {
    const date = new Date();
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${cName}=${cValue}; ${expires}; path=/`;
  }

  const toggleConsent = (consentGiven) => {
    if (document.cookie) {
      if (consentGiven) {
        const tagManagerArgs = {
          gtmId: 'GTM-KF2QRNQ',
        };
        TagManager.initialize(tagManagerArgs);
        document.cookie = `cookieConsent=true; max-age=31536000; path=/`;
      }
      if (!consentGiven) {
        const cookies = document.cookie.split(';');
        // Clear all cookies except for necessary ones
        cookies.forEach((cookie) => {
          const cookieParts = cookie.split('=');
          const cookieName = cookieParts[0].trim();
          // csrf token is necessary for django
          if (cookieName !== 'csrftoken') {
            expireCookie(cookieName, '');
          }
        });
        // Set cookieConsent to false
        document.cookie = `cookieConsent=false; max-age=31536000; path=/`;
      }
      setAccept(consentGiven);
      setConsentBannerOpen(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CookieConsentBanner accept={accept} toggleConsent={toggleConsent} consentBannerOpen={consentBannerOpen} />
      <AppRoutes user={user} />
      {!consentBannerOpen && user?.category !== 'STAFF' && (
        <Zoom in unmountOnExit>
          <Fab
            sx={{ position: 'fixed', bottom: '10px', right: '10px', p: 1 }}
            variant="extended"
            size="small"
            aria-label="Cookie preferences"
            onClick={() => setConsentBannerOpen(true)}
          >
            {matches ? '🍪 Cookie preferences' : '🍪'}
          </Fab>
        </Zoom>
      )}
    </ThemeProvider>
  );
}
export default LoginApp;
