import { dayjs } from '../../Utils/DayJs/dayjsWrapper';
import { BingCampaignDataByDateType } from '../../Types/insightstypes';
import LoadingScreen from './Components/LoadingScreen';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useInsightsQuery } from '../../Context/InsightsQueryContext';
import { useQuery } from '@tanstack/react-query';
import ErrorBoundary from './Components/ErrorBoundary';
import BingMultiView from './BingMultiView';
import React, { useEffect } from 'react';
import fetchAggregatedData from '../../Services/InsightsServices/fetchAggregatedData';
import fetchBingCampaignData from '../../Services/InsightsServices/fetchBingCampaignData';

type QueryReturnType = {
  data: { data: BingCampaignDataByDateType[] };
  isLoading: boolean;
};

function BingDataViews() {
  const {
    queryparams,
    isReadyToRun,
    shallCompare,
    alternateStartDate,
    alternateEndDate,
    setPlatformsEnabled,
    setPlatforms,
  } = useInsightsQuery();

  //Ordinary by day
  const { data: bingcampaigndata, isLoading: isLoadingBingCampaignData } = useQuery<QueryReturnType>({
    queryKey: ['bingcampaigndata', { ...queryparams }],
    queryFn: () => fetchBingCampaignData(queryparams),
    enabled: isReadyToRun,
  });

  //Ordinary Comparison by day
  const { data: bingcampaignComparisonData, isLoading: isLoadingBingCampaignComparisonData } =
    useQuery<QueryReturnType>({
      queryKey: [
        'bingcampaignComparisonData',
        { ...queryparams, startdate: alternateStartDate, enddate: alternateEndDate },
      ],
      queryFn: () =>
        fetchBingCampaignData({
          ...queryparams,
          startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
          enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
        }),
      enabled: isReadyToRun && shallCompare,
    });

  //Aggregated for a specific period
  const { data: bingcampaigndataTotal, isLoading: isLoadingBingCampaignDataTotal } = useQuery({
    queryKey: ['aggregateddataForBing', { ...queryparams }],
    queryFn: () => fetchAggregatedData({ ...queryparams, channels: ['bing'] }),
    enabled: isReadyToRun,
  });

  //Aggregated comparison for a specific period
  const { data: bingTotalComparison, isLoading: isLoadingBingTotalComparison } = useQuery({
    queryKey: ['aggregateddataComparisonforBing', { 
      ...queryparams, 
    channels: ['bing'], 
    startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
    enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'), }],
    queryFn: () =>
      fetchAggregatedData({
        ...queryparams,
        channels: ['bing'],
        startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
        enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
      }),
    enabled: isReadyToRun && shallCompare,
  });

  useEffect(() => {
    setPlatformsEnabled(false);

    setPlatforms(['bing']);

    return () => {
      setPlatformsEnabled(true);
    };
  }, []);

  const tooLittleData =
    (!bingcampaigndata?.data?.data || bingcampaigndata?.data?.data?.length === 0) && !isLoadingBingCampaignData;

  const tooLittleComparisonData =
    shallCompare &&
    (!bingTotalComparison?.data?.data ||
      !bingcampaignComparisonData?.data?.data ||
      bingcampaignComparisonData?.data?.data?.length === 0) &&
    !isLoadingBingCampaignComparisonData;

  return isLoadingBingCampaignData || isLoadingBingCampaignDataTotal || isLoadingBingCampaignComparisonData ? (
    <LoadingScreen />
  ) : (
    <ErrorBoundary>
      {tooLittleData || tooLittleComparisonData ? (
        <>
          {tooLittleData && (
            <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
              <AlertTitle>Info</AlertTitle>
              There's not enough data. Please select a different date range.
            </Alert>
          )}
          {tooLittleComparisonData && (
            <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
              <AlertTitle>Info</AlertTitle>
              There's not enough data to compare with the selected date range.
            </Alert>
          )}
        </>
      ) : (
        <BingMultiView
          dataByDay={bingcampaigndata?.data?.data}
          comparisondata={bingcampaignComparisonData?.data?.data || []}
          dataTotal={bingcampaigndataTotal?.data?.data?.[0] || null}
          comparisonTotal={bingTotalComparison?.data?.data?.[0] || null}
        />
      )}
    </ErrorBoundary>
  );
}

export default BingDataViews;
