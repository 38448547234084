import { InsightsRequest } from 'Types/insightstypes';
import http from 'Utils/Http';

const fetchAggregatedBudgetData = async (params: InsightsRequest) => {
  const { data } = await http.post('/api/insights/aggregatedbudgetdata', { ...params });

  return data;
};

export default fetchAggregatedBudgetData;
