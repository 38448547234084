import React, { useMemo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useQuery } from '@tanstack/react-query';
import { useInsightsQuery } from 'Context/InsightsQueryContext';
import fetchPortalUsers from 'Services/InsightsServices/fetchPortalUsers';
import { PortalUser } from 'Types/insightstypes';

type Props = {};

const PortalUserSelector = (props: Props) => {
  const { setPortalUser } = useInsightsQuery();
  const { data, isLoading } = useQuery<PortalUser[]>({
    queryKey: ['organizations'],
    queryFn: fetchPortalUsers,
  });

  const portalusers = useMemo(() => {
    if (!data || data.length === 0) return [];
    return (
      data?.map((org) => ({
        id: org.id,
        label: org.name,
        currency: org.currency,
      })) || []
    );
  }, [data?.length]);

  return (
    <Grid item xs={12} sm={4}>
      <Autocomplete
        size="small"
        clearOnEscape
        loading={isLoading}
        options={portalusers}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        id="portaluser-filter-selector"
        sx={{ width: '100%' }}
        renderInput={(params) => {
          return (
            <TextField
              defaultChecked={true}
              InputLabelProps={{ shrink: true }}
              {...params}
              label={isLoading ? <CircularProgress color="inherit" size={20} /> : 'Organization filter'}
              placeholder="Organization filter"
            />
          );
        }}
        onChange={(e, value) => {
          const organization = data.find((org) => org.id === value?.id);
          setPortalUser(organization);
        }}
      />
    </Grid>
  );
};

export default PortalUserSelector;
