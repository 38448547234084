/* eslint-disable react/forbid-prop-types */
import React, { lazy, Suspense } from 'react';

import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { MuiNotificationProvider } from 'Context/MuiNotificiationContext';
import InsightsPortalPlayground from 'Pages/Insights/InsightsPortal';
import { useAuth } from '../../Context/AuthContext';
import IdleHandler from '../IdleHandler/IdleHandler';
import Loading from '../Loading';

const InHousePortalApp = lazy(() => import('../../InHousePortalApp'));

function Dispatcher() {
  const { user } = useAuth();

  if (user.category === 'CLIENT') {
    return (
      <Suspense fallback={<Loading />}>
        <IdleHandler />
        <ErrorBoundary>
          <div style={{ height: '100vh' }}>
            <InsightsPortalPlayground />
          </div>
        </ErrorBoundary>
      </Suspense>
    );
  }

  if (user?.category === 'STAFF' || user?.category === 'PARTNER') {
    return (
      <Suspense fallback={<Loading />}>
        <IdleHandler />
        <ErrorBoundary>
          <MuiNotificationProvider>
            <InHousePortalApp user={user} />
          </MuiNotificationProvider>
        </ErrorBoundary>
      </Suspense>
    );
  }

  return null;
}

export default Dispatcher;
