import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import useLocalStorage from '../Hooks/useLocalStorage.ts';

export const AuthContext = React.createContext({ user: null, loadingUser: false });

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [activeClient, setActiveClient] = useLocalStorage('activeClient', null);
  const [loadingUser, setLoadingUser] = useState(false);

  async function signOut() {
    setUser(null);
    setActiveClient('');
    await axios.get('/logout');
  }

  const selectClient = (clientname) => {
    /**
     * Make sure chosen portal applications is among
     * the active users available choices.
     */
    // eslint-disable-next-line camelcase
    const { app_access_choices: appchoices } = user;
    const appnames = appchoices.map((app) => app.name);
    if (appnames.indexOf(clientname) >= 0) return clientname;
    return undefined;
  };

  const login = useMemo(
    () => ({
      user,
      setUser,
      loadingUser,
      setLoadingUser,
      signOut,
      activeclient: activeClient,
      setactiveclient: (clientname) => setActiveClient(() => selectClient(clientname)),
    }),
    [user, user?.category, user?.uuid, activeClient, activeClient]
  );

  return <AuthContext.Provider value={login}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const useAuth = () => React.useContext(AuthContext);
