import Container from '@mui/material/Container';
import React, { useState, useTransition } from 'react';
import Footer from './Components/Footer';

import { BLACK, LIGHTBEIGE } from 'Utils/colors';
import AggregatedDataByDateView from './AggregatedDataByDateView';
import BingDataView from './BingDataView';
import PortalUserSelector from './Components/PortalUserSelector';
import GoogleCampaignDataViews from './GoogleCampaignDataViews';
import MetaDataView from './MetaDataView';
import BudgetDataViews from './BudgetDataViews';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import InsightsQueryProvider from 'Context/InsightsQueryContext';
import CountryFilterSelector from './Components/CountryFilterSelector/index';
import IncludeBrandSwitch from './Components/IncludeBrandSwitch';
import PlatformSelector from './Components/PlatformSelector';
import KeywordDataBeeSwarmChartView from './KeywordDataBeeSwarmChartView';
import KeywordDataTableView from './KeywordDataTableView';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useAuth } from 'Context/AuthContext';
import CampaignTypeDataBarChartView from './CampaignTypeDataBarChartView';
import DateRangeSelector from './Components/DateRangeSelector';
import ErrorBoundary from './Components/ErrorBoundary';
import PortalUser from './Components/PortalUser';
import PrivateFunction from './Components/PrivateFunction/PrivateFunction';
import SplashScreen from './Components/SplashScreen/SplashScreen';
import AmandaAiLogo from './Components/TextLogo';

type Props = {};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: '100%', paddingLeft: '16px' }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function InsightsPortal({}: Props) {
  const { signOut }: any = useAuth();
  const [isPending, startTransition] = useTransition();
  const [tabs, setTabs] = useState(0);

  function handelTabChange(e: any, val: any) {
    setTabs(val);
  }

  return (
    <InsightsQueryProvider>
      {/* <PortalUser />
        Sets the portaluser for non staff users */}
      <PortalUser />
      <Grid
        item
        xs={12}
        sx={{
          paddingLeft: '24px',
          paddingRight: '16px',
          backgroundColor: BLACK,
          minHeight: '84px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <AmandaAiLogo width={144} />
        <Button sx={{ color: 'white', position: 'relative', bottom: '-0.2rem' }} onClick={signOut} variant="text">
          Sign Out
        </Button>
        {/* <SplashScreen /> */}
      </Grid>

      <Container
        sx={{
          maxWidth: '100% !important',
          paddingTop: '24px',
          paddingBottom: '36px',
          backgroundColor: LIGHTBEIGE,
          minHeight: '86vh',
        }}
      >
        <Grid container spacing={2}>
          <PrivateFunction>
            <Grid item xs={12}>
              <Stack direction="column" spacing={2}>
                <PortalUserSelector />
                <Divider />
              </Stack>
            </Grid>
          </PrivateFunction>
          <Grid item sm={12} md={12}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="start">
              <ErrorBoundary>
                <CountryFilterSelector />
                <IncludeBrandSwitch />
                <PlatformSelector />
              </ErrorBoundary>
            </Stack>
          </Grid>
          <Grid item sm={12}>
            <ErrorBoundary>
              <DateRangeSelector />
            </ErrorBoundary>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: '22px' }}>
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={tabs}
                onChange={(event, val) =>
                  startTransition(() => {
                    handelTabChange(event, val);
                  })
                }
              >
                <Tab label="Channels" />
                <Tab label="Keywords" />
                <Tab label="Campaign Type" />
                <Tab label="Google" />
                <Tab label="Bing" />
                <Tab label="Meta" />
                <Tab label="Budget" />
              </Tabs>
            </Box>
          </Grid>
          <TabPanel value={tabs} index={0}>
            <Grid item xs={12} sx={{ paddingBottom: '48px' }}>
              <ErrorBoundary>
                <AggregatedDataByDateView />
              </ErrorBoundary>
            </Grid>
          </TabPanel>
          <TabPanel value={tabs} index={1}>
            <Grid item xs={12} sx={{ paddingBottom: '48px' }}>
              <ErrorBoundary>
                <KeywordDataBeeSwarmChartView />
              </ErrorBoundary>
              <ErrorBoundary>
                <KeywordDataTableView />
              </ErrorBoundary>
            </Grid>
          </TabPanel>
          <TabPanel value={tabs} index={2}>
            <Grid item sx={{ paddingBottom: '48px' }}>
              <ErrorBoundary>
                <CampaignTypeDataBarChartView />
              </ErrorBoundary>
            </Grid>
          </TabPanel>
          <TabPanel value={tabs} index={3}>
            <Grid item sx={{ paddingBottom: '48px' }}>
              <ErrorBoundary>
                <GoogleCampaignDataViews />
              </ErrorBoundary>
            </Grid>
          </TabPanel>
          <TabPanel value={tabs} index={4}>
            <Grid item sx={{ paddingBottom: '48px' }}>
              <ErrorBoundary>
                <BingDataView />
              </ErrorBoundary>
            </Grid>
          </TabPanel>
          <TabPanel value={tabs} index={5}>
            <Grid item sx={{ paddingBottom: '48px' }}>
              <ErrorBoundary>
                <MetaDataView />
              </ErrorBoundary>
            </Grid>
          </TabPanel>
          <TabPanel value={tabs} index={6}>
            <Grid item sx={{ paddingBottom: '48px' }}>
              <ErrorBoundary>
                <BudgetDataViews />
              </ErrorBoundary>
            </Grid>
          </TabPanel>
        </Grid>
      </Container>

      <Grid item sm={12} md={12}>
        <Footer />
      </Grid>
    </InsightsQueryProvider>
  );
}

export default InsightsPortal;
