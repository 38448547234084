import { dayjs } from '../../Utils/DayJs/dayjsWrapper';
import { FacebookCampaignDataByDateType } from '../../Types/insightstypes';
import LoadingScreen from './Components/LoadingScreen';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useInsightsQuery } from '../../Context/InsightsQueryContext';
import { useQuery } from '@tanstack/react-query';
import ErrorBoundary from './Components/ErrorBoundary';
import MetaDataMultiView from './MetaDataMultiView';
import React, { useEffect, useMemo } from 'react';
import fetchMetaAggregatedData from '../../Services/InsightsServices/fetchMetaAggregatedData';
import fetchMetaCampaignData from '../../Services/InsightsServices/fetchMetaCampaignData';

type QueryReturnType = {
  data: { data: FacebookCampaignDataByDateType[] };
  isLoading: boolean;
};

function MetaDataViews() {
  const {
    queryparams,
    isReadyToRun,
    shallCompare,
    alternateStartDate,
    alternateEndDate,
    setPlatformsEnabled,
    setPlatforms,
  } = useInsightsQuery();

  //Ordinary by day
  const { data: facebookcampaigndata, isLoading: isLoadingFacebookCampaignData } = useQuery<QueryReturnType>({
    queryKey: ['facebookcampaigndata', { ...queryparams, channels: ['meta'] }],
    queryFn: () => fetchMetaCampaignData(queryparams),
    enabled: isReadyToRun,
  });

  //Ordinary Comparison by day
  const { data: facebookcampaignComparisonData, isLoading: isLoadingFacebookCampaignComparisonData } =
    useQuery<QueryReturnType>({
      queryKey: [
        'facebookcampaignComparisonData',
        { ...queryparams, startdate: alternateStartDate, enddate: alternateEndDate, channels: ['meta'] },
      ],
      queryFn: () =>
        fetchMetaCampaignData({
          ...queryparams,
          startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
          enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
        }),
      enabled: isReadyToRun && shallCompare,
    });

  //Aggregated for a specific period
  const { data: facebookcampaigndataTotal, isLoading: isLoadingFacebookCampaignDataTotal } = useQuery({
    queryKey: ['aggregatedFacebookData', { ...queryparams }],
    queryFn: () => fetchMetaAggregatedData({ ...queryparams, channels: ['meta'] }),
    enabled: isReadyToRun,
  });

  //Aggregated comparison for a specific period
  const { data: facebookTotalComparison, isLoading: isLoadingFacebookTotalComparison } = useQuery({
    queryKey: ['aggregateddataFacebookComparison', { 
      ...queryparams, 
    channels: ['meta'],
    startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
    enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'), }],
    queryFn: () =>
      fetchMetaAggregatedData({
        ...queryparams,
        startdate: dayjs(alternateStartDate).format('YYYY-MM-DD'),
        enddate: dayjs(alternateEndDate).format('YYYY-MM-DD'),
      }),
    enabled: isReadyToRun && shallCompare,
  });

  useEffect(() => {
    setPlatformsEnabled(false);

    setPlatforms(['meta']);

    return () => {
      setPlatformsEnabled(true);
    };
  }, []);

  const tooLittleData =
    (!facebookcampaigndata?.data?.data || facebookcampaigndata?.data?.data?.length === 0) &&
    !isLoadingFacebookCampaignData;

  const tooLittleComparisonData =
    shallCompare &&
    (!facebookTotalComparison?.data?.data ||
      !facebookcampaignComparisonData?.data?.data ||
      facebookcampaignComparisonData?.data?.data?.length === 0) &&
    !isLoadingFacebookCampaignComparisonData;

  return isLoadingFacebookCampaignData ||
    isLoadingFacebookCampaignDataTotal ||
    isLoadingFacebookCampaignComparisonData ? (
    <LoadingScreen />
  ) : (
    <ErrorBoundary>
      {tooLittleData || tooLittleComparisonData ? (
        <>
          {tooLittleData && (
            <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
              <AlertTitle>Info</AlertTitle>
              There's not enough data. Please select a different date range.
            </Alert>
          )}
          {tooLittleComparisonData && (
            <Alert variant="filled" severity="info" sx={{ marginBottom: '12px' }}>
              <AlertTitle>Info</AlertTitle>
              There's not enough data to compare with the selected date range.
            </Alert>
          )}
        </>
      ) : (
        <MetaDataMultiView
          dataByDay={facebookcampaigndata?.data?.data}
          comparisondata={facebookcampaignComparisonData?.data?.data || []}
          dataTotal={facebookcampaigndataTotal?.data?.data?.[0] || null}
          comparisonTotal={facebookTotalComparison?.data?.data?.[0] || null}
        />
      )}
    </ErrorBoundary>
  );
}

export default MetaDataViews;
